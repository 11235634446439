import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "output" ]

  connect() {

  }

  open() {
    this.element.classList.toggle('active');
    $('.navigation-bar').slideToggle('200');
  }
}
