const scroll = () => {
  // Reveal Animation

  const sr = ScrollReveal({
    origin: 'top',
    distance: '20px',
    duration: 1250,
    reset: false
  });

  ScrollReveal().reveal('.text-info, .project, .contact_text, .reveal');
  ScrollReveal().reveal('.right, .left', { delay: 200 })

}

export { scroll };
