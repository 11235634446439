const activeLink = () => {
  // Active link
  document.onscroll = function () {
    const linkPortfolio = document.querySelector('.link_portfolio');
    const portfolioSection = document.getElementById('portfolio');
    const linkContact = document.querySelector('.link_contact');
    const contactSection = document.getElementById('contact');

    if (!portfolioSection) return
    if (portfolioSection.getBoundingClientRect().top <= 70 && portfolioSection.getBoundingClientRect().bottom > 70) {
      linkPortfolio.classList.add('active_link');
    } else if (contactSection.getBoundingClientRect().top <= 70) {
      linkContact.classList.add('active_link');
    } else {
      linkPortfolio.classList.remove('active_link');
      linkContact.classList.remove('active_link');
    }
  }
}

export { activeLink };
