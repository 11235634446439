const cursor = () => {
  // Cursor
  const innerCursor = document.querySelector('.inner_cursor');

  const outerCursor = document.querySelector('.outer_cursor');

  document.addEventListener('mousemove', moveCursor);

  function moveCursor(e) {
    let x = e.clientX;
    let y = e.clientY;
    innerCursor.style.left = `${x}px`;
    innerCursor.style.top = `${y}px`;
    outerCursor.style.left = `${x}px`;
    outerCursor.style.top = `${y}px`;
  }

  // Cursor On Click
  document.addEventListener('mousedown', () => {
    innerCursor.style.transform = 'translate(-50%, -50%) scale(0.75)';
    outerCursor.style.transform = 'translate(-50%, -50%) scale(1.5)';
  });

  document.addEventListener('mouseup', () => {
    innerCursor.style.transform = 'translate(-50%, -50%)';
    outerCursor.style.transform = 'translate(-50%, -50%)';
  });

  // Cursor hover
  const text = document.querySelector('#cursor-text');
  const projects = document.querySelectorAll('.project_description');

  projects.forEach(project => {
    project.addEventListener('mouseover', () => {
      text.style.opacity = "1";
      innerCursor.style.backgroundColor = "#E4E5EF"
      outerCursor.style.border = "1px solid #E4E5EF"
    });
    project.addEventListener('mouseout', () => {
      text.style.opacity = "0";
      innerCursor.style.backgroundColor = "#FF9F9F"
      outerCursor.style.border = "1px solid #FF9F9F"
    });
    project.addEventListener('mousemove', (e) => {
      const valX = Math.trunc(e.pageX / 6);
      const valY = Math.trunc(e.pageY / 6);
      const rgbColor = "rgb(" + valY + "," + valX + ",150)";
      $(".project_description").css('background-color', rgbColor);
    });
  });
}

export { cursor };
